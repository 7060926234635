import React from 'react'

import { getHomePageData, getCompanyPageData } from '../../api/strapiApi';

import Preloader from '../../components/Common/Preloader';
import MainBanner from '../PageBanners/MainBannerOne';
import About from '../Common/About';
import News from '../Common/News';
import ServicesAndProjects from '../Common/ServicesAndProjects';
import Funfact from '../Common/Funfact';
import Team from '../Common/Team';
import Map from '../Common/Map';
import GoTop from '../Common/GoTop';
import ContactForm from '../Common/ContactForm';
import ReferencesList from '../Common/ReferencesList';
import Geschaeftsfuehrung from '../Common/Geschaeftsfuehrung';

class HomePage extends React.Component {

    state = {
        loading: true,
        homepageData: null
    };

    componentDidMount() {
        console.log("Home mounted!");
        document.title = "TBU - Startseite";
        Promise.all([getHomePageData(), getCompanyPageData()]).then(prom => {
            console.log(prom);
            const homepageData = prom[0].content;
            const companyData = prom[1].content;
            homepageData.geschaeftsfuhrungItems = companyData.geschaeftsfuhrungItems;
            this.setState({ loading: false, homepageData: homepageData });
            if (window.location.hash !== '') {
                document.getElementById(window.location.hash.replace('#', ''))
                    .scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? <Preloader /> :
                    <>
                        {/* {
                            !this.props.isMobile &&
                            <MainBanner image={this.state.homepageData.headerImage} />
                        } */}
                        <MainBanner image={this.state.homepageData.headerImage} logo_position={this.state.homepageData.logo_position} />
                        <About data={this.state.homepageData.about_section} useMobileView={this.props.isMobile} />
                        <Funfact items={this.state.homepageData.icon_items} background_image={this.state.homepageData.background_image}/>
                        <News projects={this.state.homepageData.news_projects} text={this.state.homepageData.news_projects_text} isMobile={this.props.isMobile}  />
                        <ServicesAndProjects items={this.state.homepageData.services_and_projects_items} title={this.state.homepageData.services_and_projects_title} isMobile={this.props.isMobile} />
                        <Team items={this.state.homepageData.team_section_image_items} isMobile={this.props.isMobile} team_section_text={this.state.homepageData.team_section_text} text={this.state.homepageData.team_text}/>
                        {/* <References items={this.state.homepageData.reference_items} pdfLink={this.state.homepageData.references_pdf_link} /> */}
                        <Geschaeftsfuehrung items={this.state.homepageData.geschaeftsfuhrungItems} />
                        <ReferencesList references={this.state.homepageData.references} pdfLink={this.state.homepageData.references_pdf_link}/>
                        <Map contact_data={this.state.homepageData.contact_section} useMobileView={this.props.isMobile} />
                        <ContactForm/>
                    </>
                }
                <GoTop scrollStepInPx="50" delayInMs="16.66" />

            </React.Fragment>
        );
    }
}

export default HomePage;