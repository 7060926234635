import React from "react";
import { getKarriereData } from '../../api/strapiApi';

// core components
// import IndexNavbar from "../components/Navbars/IndexNavbar.js";

// sections for this page
import Preloader from "../Common/Preloader";


class Karriere extends React.Component {

    state = {
        loading: true,
        projectsData: null
    };

    componentDidMount() {
        document.title = "TBU - Karriere";

        getKarriereData().then(data => {
            this.setState({ loading: false, karriereData: data.content, selectedItem: data.content.stellenanzeigen[0] });
        });
    }

    renderStellenanzeige() {
        // const stelle = this.state.karriereData.stellenanzeigen[this.state.selectedItem];
        return <div className="stellenanzeige">
            <h2>{this.state.selectedItem.bezeichnung}</h2>
            <p>{this.state.selectedItem.beschreibung}</p>
        </div>;
    }

    render() {
        return (
            this.state.loading ? <Preloader /> :
                <>
                    <div className="karriere-wrapper">
                        <div className="container">
                            <div className="section-title">
                                <h1>Karriere</h1>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.karriereData.karriereObererText
                                    }
                                    {
                                        <ul>
                                            {
                                                this.state.karriereData.stellenanzeigen.map((e, idx) =>
                                                    <li key={idx} className={e === this.state.selectedItem ? "selected" : "unselected"} onClick={() => this.setState({ selectedItem: e})}>
                                                        {e.bezeichnung}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    }
                                </div>

                                <div className="col block">
                                    {
                                        this.renderStellenanzeige()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default Karriere;
