import React from 'react';

const Team = props => {
    return (
        <section className="strategy-area" id="team">
            {
                <div className="container">
                    <div className="team-description">
                        <div className="">
                            <div className="section-title">
                                <h2>Unser <span>Team</span></h2>
                                <p>{props.text}</p>
                            </div>
                            <p>
                                {props.team_section_text}
                            </p>

                        </div>
                    </div>
                </div>

            }
        </section>
    );
}

export default Team;