import React from "react";

// sections for this page
import { getDatenschutzPageData } from '../../api/strapiApi';
import Preloader from '../../components/Common/Preloader';


class Datenschutz extends React.Component {
    state = {
        loading: true,
        projectsData: null
    };

    componentDidMount() {
        document.title = "TBU - Datenschutz";
        getDatenschutzPageData().then(data => {
            this.setState({ loading: false, data: data.content });
        });
    }


    render() {
        return (
            this.state.loading ? <Preloader /> :
            <>

                <section className="section-datenschutz">
                    {/* <div className="wrapper"> */}
                        <div className="container">
                            <div className="section-title">
                                <h2>Datenschutzerklärung</h2>
                            </div>
                            <p className="big-datenschutz-text">
                                {
                                    this.state.data.datenschutzerklaerung
                                }
                            </p>
                            {/* <div className="section-title">
                                <h2>Haftung für Links</h2>
                            </div> */}
                            {/* <p className="big-datenschutz-text haftung-text">
                                {
                                    this.state.data.haftung
                                }
                            </p> */}
                        </div>
                    {/* </div> */}
                </section>
            </>
        );
    }
}

export default Datenschutz;
