import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = (props) => (
    <section className="page-not-found-section">
        {/* <div className="container"> */}
            <img src={require('../../assets/images/tbu_logo.png')} alt="not-found" />
            <h1 style={{ textAlign: "center" }}>404 - Seite konnte nicht gefunden werden</h1>
            <h2 style={{ textAlign: "center" }}>
                <Link to="/">Zurück zur Startseite </Link>
            </h2>
        {/* </div> */}
    </section>
);

export default ErrorPage;
