import React from "react";

import {
  Container,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Card, CardBody, CardImg, CardText
} from "reactstrap";

import "react-image-lightbox/style.css";
import {largeImageUrl} from '../../api/transform_image_url';


const News = props => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const items = props.projects;

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = (activeIndex + 1) % items.length;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = (activeIndex + items.length - 1) % items.length;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  let content;
  if (props.isMobile) {
    content = 
    <div className="container">
      {items.map((item, index) =>
        <div className="card-container" key={index}>
          <Card>
            <div className="zoom-wrapper"><CardImg variant="top" src={item.src.replace('cms/', 'cms/small_')} /></div>
            <CardBody>
              <CardText>
                {item.description}
              </CardText>
            </CardBody>
          </Card>
        </div>
      )}
      </div>
    ;
  } else {
    content = <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {items.map((item, index) => {
        return (
          <CarouselItem
            onExiting={onExiting}
            onExited={onExited}
            key={index}
          >
            {/* <Link to={"/project-details:id=" + item.id}> */}
              <img src={largeImageUrl(item.src)} alt={item.altText} />
            {/* </Link> */}

            <div className="carousel-caption d-none d-md-block">
              <h6>{item.description}</h6>
            </div>
          </CarouselItem>
        );
      })}
      <a
        className="carousel-control-prev"
        data-slide="prev"
        href="#pablo"
        onClick={e => {
          e.preventDefault();
          previous();
        }}
        role="button"
      >
        <i className="fa fa-chevron-left"></i>
      </a>
      <a
        className="carousel-control-next"
        data-slide="next"
        href="#pablo"
        onClick={e => {
          e.preventDefault();
          next();
        }}
        role="button"
      >
        <i className="fa fa-chevron-right"></i>
      </a>
    </Carousel>;
  }
  return (
    <>
      <div className="section-news" id="section-news">
        <Container>
          <div className="section-title">
            <h2 ><span>Aktuelle Baustellen</span></h2>
            <p>
              {
                props.text
              }
            </p>
          </div>
              {
                content
              }

        </Container>
      </div>
    </>
  );
}

export default News;
