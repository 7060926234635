import React from "react";
import Buero from "../Common/Buero.jsx";
import { getHomePageData, getCompanyPageData } from "../../api/strapiApi";
import Preloader from "../Common/Preloader.jsx";
import Team from "../Common/Team.jsx";
import Geschaeftsfuehrung from "../Common/Geschaeftsfuehrung.jsx";


class Company extends React.Component {
    state = {
        loading: true,
        companyData: null
    };

    componentDidMount() {
        document.title = "TBU - Unternehmen";
        Promise.all([getHomePageData(), getCompanyPageData()]).then(prom => {
            const homepageData = prom[0].content;
            const companyData = prom[1].content;

            this.setState({ loading: false, homepageData: homepageData, companyData: companyData });
        });
    }

    render() {
        return (
            <>
                {
                    this.state.loading ? <Preloader /> :
                        <div className="company-wrapper">
                            <div className="main">
                                <div style={this.props.isMobile ? { height: '40px' } : { height: '80px' }}></div>
                                {/* <CompanyBanner headerImage={this.state.companyData.headerImage} title="Unser Unternehmen"></CompanyBanner> */}
                                {/* <Leistungen leistungen={this.state.companyData.leistungsData} tatigungen={this.state.companyData.tatigungsData}></Leistungen> */}
                                <Team items={this.state.homepageData.team_section_image_items} isMobile={this.props.isMobile} team_section_text={this.state.homepageData.team_section_text} text={this.state.homepageData.team_text} />
                                <Geschaeftsfuehrung items={this.state.companyData.geschaeftsfuhrungItems} />
                                <Buero items={this.state.companyData.buro_items} />
                                <img className="bg-image" src={this.state.homepageData.background_image} alt="bg" />
                            </div>
                        </div>
                }
            </>
        );
    }
}



export default Company;