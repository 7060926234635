import * as transforms from "./transformRequest";
import {BACKEND_URL} from '../environment';
const fetch = require("node-fetch");


export const getHomePageData = () =>
  fetch(BACKEND_URL + "/startseite")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.homepage(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the hompage data.", error);
    });

export const getProjectsPageData = () =>
  fetch(BACKEND_URL + "/projektseite")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.projects(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the projects data.", error);
    });

export const getImpressumPageData = () =>
  fetch(BACKEND_URL + "/impressum")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.impressum(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getCompanyPageData = () =>
  fetch(BACKEND_URL + "/unternehmen")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.company(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getDatenschutzPageData = () =>
  fetch(BACKEND_URL + "/Datenschutzerklaerung")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.datenschutz(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getKarriereData = () =>
  fetch(BACKEND_URL + "/karriere")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.karriere(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });
