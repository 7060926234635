import React from 'react'

import { getProjectsPageData } from '../../api/strapiApi';

import Preloader from '../../components/Common/Preloader';
import GoTop from '../Common/GoTop';
import HalfScreenCarousel from '../Common/HalfScreenCarousel';
import ProjectDetailDescription from '../Common/ProjectDetailDescription';

class ProjectDetail extends React.Component {

    state = {
        loading: true,
        project: null
    };

    componentDidMount() {
        document.title = "TBU - Projektübersicht";
        const { id } = this.props.match.params;
        getProjectsPageData().then(data => {
            const projectItem = data.content.projectItems.find(p => ":id=" + p.id === id);
            this.setState({ loading: false, project: projectItem });
        });
    }

    render() {
        return this.state.loading ? <Preloader /> :
            <React.Fragment>
                <HalfScreenCarousel images={this.state.project.images} />
                <ProjectDetailDescription project={this.state.project} />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
                
            </React.Fragment>;
    }
}

export default ProjectDetail;