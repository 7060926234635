import '../../assets/css//bootstrap/scss/bootstrap.scss';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/style.scss';
import '../../assets/css/responsive.scss';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, withRouter } from 'react-router-dom';
import Sidebar from "react-sidebar";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {getProjectTypeString, getProjectTypeUrl, project_types} from '../../api/mappings';

import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";
import MobileNavbarStyle from './MobileNavbarStyle';

class NavigationHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            collapsed: true,
            scrollYOffset: 170
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHandler);
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    scrollHandler = () => {
        if (this.props.isMobile) {
            this.setState({
                collapsed: true,
            });
            return;
        }

        let scrollYOffset = this.props.location.pathname === "/" ? 170 : 50;

        if (window.scrollY > scrollYOffset) {
            // this.elementId.classList.add("is-sticky");
            this.setState({
                collapsed: false,
            });
        } else {
            // this.elementId.classList.remove("is-sticky");
            this.setState({
                collapsed: true,
            });
        }
    }

    componentDidMount() {
        this.elementId = document.getElementById("navbar");
        this.scrollListener = document.addEventListener("scroll", this.scrollHandler);
    }

    goToId = (e) => {
        window.location.hash = e;
        window.location.refresh(true);
    }

    scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    project_dropdown = () =>
        <UncontrolledDropdown nav>
            <DropdownToggle caret color="default" nav onClick={e => e.preventDefault()}>
                Projekte
            </DropdownToggle>
            <DropdownMenu>
                {
                    project_types.map(t => 
                        <DropdownItem href={getProjectTypeUrl(t)}>
                    {getProjectTypeString(t)}
                         </DropdownItem>
                        )
                }
            </DropdownMenu>
        </UncontrolledDropdown>;

    renderMenus = () => {
        return (
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <AnchorLink onClick={() => this.scrollTop()} className="nav-link" href="#home">Startseite</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink offset={() => 60} className="nav-link" href="#section-news">Aktuelles</AnchorLink>
                </li>
                <li className="nav-item">
                    {this.project_dropdown()}
                </li>
                <li className="nav-item">
                    <AnchorLink offset={() => 0} className="nav-link" href="#section-services-projects">Leistungen</AnchorLink>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/unternehmen">Unternehmen</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/karriere">Karriere</Link>
                </li>
                <li className="nav-item">
                    <AnchorLink offset={() => 30} className="nav-link" href="#section-references">Referenzen</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink offset={() => -40} className="nav-link" href="#contact">Kontakt</AnchorLink>
                </li>
            </ul>
        );
    }

    renderSidebar = () => <Sidebar
        sidebar={
            <div className="sidebar-content">

                <div className="toggler-navbar-upper"></div>
                <div className="mobile-logo-wrapper">
                    <img alt="TBU Logo" className="sidebar-navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                </div>
                <div className="border-bottom" />
                <ul >
                    <li className="drawer-item">
                        <AnchorLink onClick={() => this.scrollTop()} className="drawer-link" href="#home">Startseite</AnchorLink>
                    </li>
                    <li className="drawer-item">
                        <AnchorLink offset={() => 60} className="drawer-link" href="#section-news">Aktuelles</AnchorLink>
                    </li>
                    <li className="drawer-item">
                        <Link className="drawer-link" to="/projects">Projekte</Link>
                    </li>
                    <li className="drawer-item">
                        <AnchorLink offset={() => 0} className="drawer-link" href="#section-services-projects">Leistungen</AnchorLink>
                    </li>
                    <li className="drawer-item">
                        <Link className="drawer-link" to="/unternehmen">Unternehmen</Link>
                    </li>
                    <li className="drawer-item">
                        <Link className="drawer-link" to="/karriere">Karriere</Link>
                    </li>
                    <li className="drawer-item">
                        <AnchorLink offset={() => 30} className="drawer-link" href="#section-references">Referenzen</AnchorLink>
                    </li>
                    <li className="drawer-item">
                        <AnchorLink offset={() => -40} className="drawer-link" href="#contact">Kontakt</AnchorLink>
                    </li>
                </ul>

            </div>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={MobileNavbarStyle}
    >
        <div />
    </Sidebar>;

    render() {
        const { collapsed, sideBarOpen } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        return (
            <div>
                {
                    this.renderSidebar()
                }
                <nav id="navbar" key={"home-nav-bar-collapsed"} className="navbar navbar-expand-lg navbar-light bg-light header-sticky">
                    <div className="container">
                        <div className="navbar-upper" />
                        <Link
                            className="navbar-brand"
                            to="/"
                        >
                        </Link>
                        {
                            this.props.isMobile &&
                            <div className="toggler-wrapper">
                                <div className="toggler-navbar-upper" />
                                <img alt="TBU Logo" className="toggler-navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                                <button
                                    onClick={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                        }
                        <div className={classOne} id="navbarSupportedContent">
                            {this.renderMenus()}
                        </div>
                    </div>
                </nav>
                <ReactCSSTransitionGroup
                    transitionName="navbar-animation"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}>
                    {
                        !collapsed &&
                        <nav id="navbar-uncollapsed" key={"home-nav-bar-uncollapsed"} className="navbar navbar-expand-lg navbar-light bg-light header-sticky is-sticky">
                            <div className="container">
                                <div className="navbar-upper" />
                                <Link
                                    className="navbar-brand"
                                    to="/"
                                >
                                    <img alt="TBU Logo" className="navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                                </Link>
                                {
                                    this.props.isMobile &&
                                    <div className="toggler-wrapper">
                                        <div className="toggler-navbar-upper" />
                                        <img alt="TBU Logo" className="toggler-navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                                        <button
                                            onClick={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                                            className={classTwo}
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    </div>
                                }
                                <div className={classOne} id="navbarSupportedContent">
                                    {this.renderMenus()}
                                </div>
                            </div>
                        </nav>
                    }
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

export default withRouter(NavigationHome);
