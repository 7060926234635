export const projectTypeMapping = {
    "*": "Alle",
    "oeffentliche_bauten": "Öffentliche Bauten",
    "sigeko": "SiGeKo",
    "gewerbebau": "Gewerbebau",
    "ingenieurbau": "Ingenieurbau",
    "gutachten": "Gutachten",
    "wohnungsbau": "Wohnungsbau",
    "holzbau": "Holzbau",
    "aufstockung": "Aufstockung / Erweiterung"
}

export const project_types = Object.keys(projectTypeMapping);

export const getProjectTypeString = (project_type) => projectTypeMapping[project_type];

export const getProjectTypeUrl = (project_type) => "/projects" + (project_type === "*" ? "" : ":type=" + project_type);
