import React from "react";

class ProjectDetailDescription extends React.Component {
  state = {
    showGoBottomArrow: true,
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      var canExpand = window.scrollY < 100;
      if (this.state.showGoBottomArrow != canExpand) {
        this.setState({ showGoBottomArrow: canExpand });
      }
    });
  }

  render() {
    return (
      <>
        <section
          className="section-project-detail-description"
          id="section-project-detail-description"
        >
          {
            <div className="to-details">
              <div className="col lg-6 md-6">
                <i
                  className={
                    this.state.showGoBottomArrow
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.showGoBottomArrow) {
                      document
                        .getElementById("section-project-detail-description")
                        .scrollIntoView({ behavior: "smooth" });
                    } else {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                  }}
                >
                  <a> Details</a>
                </i>
              </div>
            </div>
          }
          <div className="container">
            {
              <p className="source">{this.props.project.quelle}</p>
            }
            <div className="row details">
              <div className="col lg-3">
                <div className="section-title">
                  <h2>
                    <span>Projekt</span>
                  </h2>
                  <h4>{this.props.project.description}</h4>
                </div>
              </div>
              <div className="col lg-3">
                <div className="section-title">
                  <h2>
                    <span>Bauherr</span>
                  </h2>
                  {<h4>{this.props.project.bauherren}</h4>}
                </div>
              </div>
              <div className="col lg-3">
                <div className="section-title">
                  <h2>
                    <span>Architekt</span>
                  </h2>
                  {<h4>{this.props.project.architects}</h4>}
                </div>
              </div>
              <div className="col lg-3">
                <div className="section-title">
                  <h2>
                    <span>Leistungen</span>
                  </h2>
                  <h4>{this.props.project.fertigstellung}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ProjectDetailDescription;
